<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex xs10>
            <h2>{{ heading }}</h2>
          </v-flex>
        </v-layout>
        <v-divider class="my-3" />
      </v-flex>
      <v-flex
        xs12
        sm6
      >
        <v-form
          ref="form"
          lazy-validation
        >
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-flex xs12>
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'visibility' : 'visibility_off'"
                  :rules="[rules.required, rules.password]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Enter New Password"
                  hint=""
                  counter
                  @click:append="show1 = !show1"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="password2"
                  :append-icon="show2 ? 'visibility' : 'visibility_off'"
                  :rules="[rules.required]"
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-2"
                  label="Confirm New Password"
                  hint="Has to match the password above"
                  value="wqfasds"
                  class="input-group--focused"
                  @click:append="show2 = !show2"
                />
              </v-flex>
            </v-flex>
            <v-flex xs12>
              <v-btn
                color="primary"
                @click="confirm()"
              >
                Confirm
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
      <v-flex
        xs12
        sm6
      >
        <v-card>
          <v-card-text>
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <span>Your password should contain</span>
                <ol>
                  <li>
                    8 to 20 characters and <v-icon
                      v-if="is8to20"
                      color="success"
                    >
                      check
                    </v-icon>
                  </li>
                  <li>
                    at least 1 Uppercase Alphabet and <v-icon
                      v-if="hasOneUpper"
                      color="success"
                    >
                      check
                    </v-icon>
                  </li>
                  <li>
                    1 Lowercase Alphabet and <v-icon
                      v-if="hasOneLower"
                      color="success"
                    >
                      check
                    </v-icon>
                  </li>
                  <li>
                    1 Number and <v-icon
                      v-if="hasOneDigit"
                      color="success"
                    >
                      check
                    </v-icon>
                  </li>
                  <li>
                    1 Special Character <v-icon
                      v-if="hasOneSpecial"
                      color="success"
                    >
                      check
                    </v-icon>
                  </li>
                </ol>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      heading: 'New Password',
      show1: false,
      show2: false,
      password: '',
      password2: '',
      rules: {
        required: value => !!value || 'Required.',
        password: v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])([A-Za-z\d]|[^\w\s]){8,20}$/.test(v) || 'Does not match the requirement',
      },
    }
  },
  computed: {
    user() {
      return this.$store.getters.user || {}
    },
    userId() {
      return this.user.userId
    },
    is8to20() {
      return /^.{8,20}$/.test(this.password)
    },
    hasOneUpper() {
      return /.*[A-Z]/.test(this.password)
    },
    hasOneLower() {
      return /.*[a-z]/.test(this.password)
    },
    hasOneDigit() {
      return /.*\d/.test(this.password)
    },
    hasOneSpecial() {
      return /.*[^\w\s]/.test(this.password)
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const { token } = this.$route.query
      if (token) {
        this.$store.dispatch('consumeToken', token)
      }
    },
    confirm() {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.password !== this.password2) {
        this.$store.dispatch('showMessage', { state: 'error', message: 'Password you entered do not match!' })
        return
      }
      this.$store.dispatch('logins/setPassword', { id: this.userId, password: this.password })
        .then(() => {
          this.$store.dispatch('logout')
          this.$router.push('/')
          this.$store.dispatch('showMessage', { state: 'success', message: 'Success! Password has been set' })
        })
    },
  },
}
</script>

<style>

</style>
